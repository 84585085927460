import {
    ElementTopicsDataType,
    ElementTopicSection,
    Topic,
    useTopicsLink,
} from 'components/element-viewer/element-topics/index';
import React, { useState } from 'react';
import { Box, Chip, Stack, Tooltip } from '@mui/material';
import ElementTopicSectionIcon from 'components/common/icon/ElementTopicSectionIcon';
import GlossaryTermPopup from 'components/glossary-term-popup';
import useBundleTranslation from 'i18n';
import styles from 'app/home/tile/preview/ElementPreviewSummary.styles';
import IconMi from 'components/common/icon/IconMi';

export default function ElementTopics({ topics, styles }: { topics: ElementTopicsDataType; styles: any }) {
    const { t } = useBundleTranslation(['components/common/element']);
    const [collapseTermSectionList, setCollapseTermSectionList] = useState<any>({});

    const sections = Object.values(topics);
    const { handleTopicClick, showTermsPopup, selectedTopicId, topicIds, setShowTermsPopup } = useTopicsLink(
        sections.reduce((acc: Array<Topic>, section: ElementTopicSection) => acc.concat(section.topics), []),
    );

    const tagsSectionKeys = Object.keys(topics).filter((key) => key == 'default-topic-type');
    const termsSectionKeys = Object.keys(topics).filter((key) => key != 'default-topic-type');

    const toggleCollapseTermSection = (sectionKey: string) => {
        const newCollapseSectionsList = { ...collapseTermSectionList };
        newCollapseSectionsList[sectionKey] = !newCollapseSectionsList?.[sectionKey];
        setCollapseTermSectionList(newCollapseSectionsList);
    };

    return (
        <>
            {termsSectionKeys.length > 0 && (
                <>
                    <Stack sx={styles.heading}>{t('glossary_terms_section_heading')}</Stack>

                    {termsSectionKeys.map((sectionKey, i) => {
                        const section = topics[sectionKey];
                        return (
                            <Stack
                                key={i}
                                sx={{ ...styles.termSection, ...styles.row, flexDirection: 'column' }}
                                width={1}
                                className={collapseTermSectionList?.[sectionKey] ? 'collapse' : 'expand'}
                            >
                                <Stack
                                    direction={'row'}
                                    onClick={() => {
                                        toggleCollapseTermSection(sectionKey);
                                    }}
                                    className={'term-section__header'}
                                >
                                    <Box sx={styles.iconHolder}>
                                        <ElementTopicSectionIcon
                                            section={section}
                                            styles={{ icon: { fontSize: '14px' } }}
                                        />
                                    </Box>
                                    <Box flexGrow={1}>{section.topicTypeName}</Box>
                                    <Box sx={{ ...styles.iconHolder, ...{ mr: 0, ml: 1 } }}>
                                        <IconMi
                                            icon={
                                                collapseTermSectionList?.[sectionKey] ? 'chevron-right' : 'chevron-down'
                                            }
                                            sx={{ fontSize: 16 }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack
                                    direction="row"
                                    flexWrap={'wrap'}
                                    sx={{ ml: -0.5, pl: '24px' }}
                                    className={'term-section__items'}
                                >
                                    {section.topics.map((topic) => {
                                        const needToCutLabel = topic.name.length > 30;
                                        const label = needToCutLabel ? topic.name.substring(0, 30) + '...' : topic.name;

                                        return (
                                            <Box sx={{ mt: 0.5, ml: 0.5 }} key={topic.topicId}>
                                                <Tooltip title={needToCutLabel ? topic.name : ''}>
                                                    <Chip
                                                        onClick={() => handleTopicClick(false, topic.topicId)}
                                                        label={label}
                                                        color="primary"
                                                    />
                                                </Tooltip>
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        );
                    })}
                </>
            )}

            <Stack sx={styles.heading}>{t('details_section_heading')}</Stack>
            {tagsSectionKeys.map((sectionKey, i) => {
                const section = topics[sectionKey];
                return (
                    <Stack key={i} sx={styles.row}>
                        <Box sx={styles.label}>
                            <ElementTopicSectionIcon section={section} styles={{ icon: styles.icon }} />
                            {section.isDefault ? t('tags') : section.topicTypeName}
                        </Box>
                        <Box sx={styles.value}>
                            <Stack direction="row" flexWrap={'wrap'} sx={{ ml: -0.5, mt: -0.5 }}>
                                {section.topics.map((topic) => {
                                    const needToCutLabel = topic.name.length > 30;
                                    const label = needToCutLabel ? topic.name.substring(0, 30) + '...' : topic.name;

                                    return (
                                        <Box sx={{ mt: 0.5, ml: 0.5 }} key={topic.topicId}>
                                            <Tooltip title={needToCutLabel ? topic.name : ''}>
                                                <Chip
                                                    onClick={() => handleTopicClick(section.isDefault, topic.topicId)}
                                                    label={label}
                                                    color="primary"
                                                    sx={{ mr: '2px' }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Box>
                    </Stack>
                );
            })}

            {showTermsPopup && (
                <GlossaryTermPopup
                    setShowPopup={setShowTermsPopup}
                    showPopup={showTermsPopup}
                    topicIds={topicIds}
                    topicId={selectedTopicId}
                />
            )}
        </>
    );
}
